import { Site } from "@equiem/web-ng-lib";
import { useContext, useEffect, useState } from "react";
import { useChatBotIsEnabledForViewerQuery } from "../generated/ai-client";

const whitelistEmails = [
  "jeremy.kirkham@equiem.com.au",
  "ryan.ghanbari@getequiem.com",
  "rinita.sur@getequiem.com",
  "jen.leibhart@getequiem.com",
  "faraz.ahmad@getequiem.com",
  "arun.thenabadu@equiem.com.au",
  "marley.fabisiewicz@getequiem.com",
  "julia.bearzatto@getequiem.com",
  "gabrielle.mcmillan@getequiem.com",
  "sarah.machon@getequiem.com",
  "katy.austin@getequiem.com",
  "james.harvey@getequiem.com",
  "eranga.manchanayake@equiem.com.au",
  // CWG internal team:
  "Michelle.Laramy@CanaryWharf.com",
  "Claire.Slater@CanaryWharf.com",
  "Francesca.Clemens@CanaryWharf.com",
  "Jade.Forrester@CanaryWharf.com",
  "Jessica.Pinkham@CanaryWharf.com",
  "Pippa.Dale@CanaryWharf.com",
  "Sophie@ThisIsVertus.com",
  "Steve.Pritchard@CanaryWharf.com",
  "Pete.Ayertey-Wilson@CanaryWharf.com",
  "Steven.Gale@CanaryWharf.com",
  "Adam.Robinson@CanaryWharf.com",
  "Will.Griggs-Leslie@CanaryWharf.com",
  "Amy.Oudot@CanaryWharf.com",
  "Natalie.Marcus@canarywharf.com",
];

export const useShowAi = (email?: string) => {
  const emailIsWhitelisted =
    email != null ? whitelistEmails.map((e) => e.toLowerCase()).includes(email.toLowerCase()) : false;

  const [show, setShow] = useState(emailIsWhitelisted);
  const { uuid } = useContext(Site);
  const { data } = useChatBotIsEnabledForViewerQuery({
    variables: {
      siteUuid: uuid,
    },
  });

  useEffect(() => {
    if (data != null) {
      setShow(emailIsWhitelisted || data.destination.chatBotIsEnabledForViewer);
    }
  }, [data, emailIsWhitelisted]);

  return show;
};
